<template>
  <div id="cgu_faq">
    <CguFaqDe v-if="country === 'de'" />
    <CguFaqFr v-else />
  </div>
</template>

<script>
import CguFaqFr from "@/components/legal/faq/CguFaqFr.vue";
import CguFaqDe from "@/components/legal/faq/CguFaqDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les CGU de FAQ dans la bonne langue.
 */
export default {
  name: "CguFaq",
  components: {
    CguFaqFr,
    CguFaqDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
